import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyB2pejoKj7Xd4Tn1vuDrgM2NTK2ftyP0mc",
  authDomain: "spotnewz-5dcb7.firebaseapp.com",
  projectId: "spotnewz-5dcb7",
  storageBucket: "spotnewz-5dcb7.appspot.com",
  messagingSenderId: "374482736232",
  appId: "1:374482736232:web:0a5316f7c558eeb56857dd",
  measurementId: "G-GF479NENLL"
};

    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
